<template lang="pug">
	.baseIcon
		span im a base icon!
</template>

<style lang="scss" scoped>
	//
</style>

<script>
	export default {
		data() {
			return {
				//
			};
		}
	};
</script>
